// V2 用の mixin を定義
// V1 とダブるものは定義しない

// svg の fill を変更する
@mixin v2-set-svg-color($color) {
  // <svg> の下の <path> の fill を変更する
  svg path {
    fill: $color;
  }
}

@mixin v2-set-svg-size($size) {
  font-size: 0;
  line-height: 0;

  svg {
    width: $size;
    height: $size;
  }
}

// svg コンポーネント の親要素に対して適用する
@mixin v2-svg-wrapper($color, $size) {
  @include v2-set-svg-color($color);
  @include v2-set-svg-size($size);
}

// untitled の svg ファイルは stroke の色が変わる。
@mixin v2-set-svg-stroke-color($color) {
  svg path {
    stroke: $color;
  }
}

@mixin v2-untitled-svg($color, $size) {
  @include v2-set-svg-stroke-color($color);
  @include v2-set-svg-size($size);
}

@mixin v2-input-field-error-outline {
  outline: 2px solid c(red100-hover);

  &:hover {
    outline: 2px solid c(red100-hover);
  }

  &:focus {
    outline: 2px solid c(red100-hover);
    box-shadow: 0 0 0 4px c(focus);
  }
}

@mixin v2-input-field-base {
  color: c(primary-body-text);
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  border: 1px solid c(border);
  border-radius: 20px;
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  align-items: center;
  outline: 2px solid transparent;
  transition: outline-color .3s ease, box-shadow .3s ease;

  &::placeholder {
    color: c(secondary-body-text);
  }

  &:hover {
    outline: 2px solid c(primary100);
  }

  &:focus {
    outline: 2px solid c(primary100);
    box-shadow: 0 0 0 4px c(focus);
  }
}

@mixin v2-drop-down-icon {
  position: relative;

  &::before {
    content: "";
    background-image: url('../../../public/images/common/chevron-down_v2.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8px;
    right: 14px;
    pointer-events: none;
  }
}
