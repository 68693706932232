// V2 用の変数定義
// ============================================================
// Colors - Light theme
// ============================================================
// Main colors
// ベースとなる色の定義
$v2-light-theme-primary: #4e46b4;
$v2-light-theme-secondary: #40a69f;
$v2-light-theme-border: #ebebeb;
// 条件によって重ねる色や透明度の定義
$v2-light-theme-hover-mix-color: #000; // ホバー時に重ねがけする色
// ホバー時の色の重ねがけ用の weight 値
// mix() に渡すため、下地の色を何%使うかで指定
$v2-light-theme-hover-mix-weight: 92%; // 重ねる色の方を8%で重ねがけ
$v2-light-theme-supportive-color-60-opacity: 56%; // rgba() に渡す
$v2-light-theme-supportive-color-10-opacity: 8%; // rgba() に渡す

$v2-light-theme-main-colors: (
  primary100: $v2-light-theme-primary,
  primary100-hover: mix($v2-light-theme-primary, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight),
  secondary100: $v2-light-theme-secondary,
  secondary100-hover:  mix($v2-light-theme-secondary, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight),
  background: #f5f5f5,
  surface: #fff,
  backdrop: rgba(#000, 56%), // モーダルとか出したときのオーバーレイのやつ
  border: $v2-light-theme-border,
  border-hover: mix($v2-light-theme-border, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight),
  primary-button-text: #fff, // テキストやアイコン用
  secondary-button-text: #000,
  primary-body-text: #000,
  secondary-body-text: #999ca0,
  primary-hover: rgba(#000, 8%),
  secondary-hover: rgba($v2-light-theme-primary, 12%), // テキストだけのボタンにホバーしたときにつける
  focus: rgba($v2-light-theme-primary, 20%), // button や input の focus リングの色
  symbol: #000,
);

// Supportive colors
$v2-light-theme-reds: get-supportive-colors('red', #d33030, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);
$v2-light-theme-yellows: get-supportive-colors('yellow', #ffb319, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);
$v2-light-theme-greens: get-supportive-colors('green', #2e7d32, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);
$v2-light-theme-pinks: get-supportive-colors('pink', #ff4e64, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);
$v2-light-theme-cyans: get-supportive-colors('cyan', #95f1d5, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);
$v2-light-theme-oranges: get-supportive-colors('orange', #b3804a, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);
$v2-light-theme-blues: get-supportive-colors('blue', #3448f0, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);
$v2-light-theme-purples: get-supportive-colors('purple', #5c33cf, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);
$v2-light-theme-browns: get-supportive-colors('brown', #725550, $v2-light-theme-hover-mix-color, $v2-light-theme-hover-mix-weight, $v2-light-theme-supportive-color-60-opacity, $v2-light-theme-supportive-color-10-opacity);

// Untitled UI Colors
// Untitled UI ベースのカラーパレット
$v2-light-theme-base-colors: get-untitled-ui-colors(
  'base',
  (
    white: #fff,
    black: #000,
  )
);

$v2-light-theme-gray: get-untitled-ui-colors(
  'gray',
  (
    25: #fcfcfd,
    50: #f9fafb,
    100: #f2f4f7,
    200: #eaecf0,
    300: #d0d5dd,
    400: #98a2b3,
    500: #667085,
    600: #475467,
    700: #344054,
    800: #1d2939,
    900: #101828,
    950: #0c111d,
  )
);

$v2-light-theme-primary: get-untitled-ui-colors(
  'primary',
  (
    25: #fcfaff,
    50: #f9f5ff,
    100: #f4ebff,
    200: #e9d7fe,
    300: #d6bbfb,
    400: #b692f6,
    500: #9e77ed,
    600: #7f56d9,
    700: #6941c6,
    800: #53389e,
    900: #42307d,
    950: #2c1c5f,
  )
);

$v2-light-theme-error: get-untitled-ui-colors(
  'error',
  (
    25: #fffbfa,
    50: #fef3f2,
    100: #fee4e2,
    200: #fecdca,
    300: #fda29b,
    400: #f97066,
    500: #f04438,
    600: #d92d20,
    700: #b42318,
    800: #912018,
    900: #7a271a,
    950: #55160c,
  )
);

$v2-light-theme-warning: get-untitled-ui-colors(
  'warning',
  (
    25: #fffcf5,
    50: #fffaeb,
    100: #fef0c7,
    200: #fedf89,
    300: #fec84b,
    400: #fdb022,
    500: #f79009,
    600: #dc6803,
    700: #b54708,
    800: #93370d,
    900: #7a2e0e,
    950: #4e1d09,
  )
);

$v2-light-theme-success: get-untitled-ui-colors(
  'success',
  (
    25: #f6fef9,
    50: #ecfdf3,
    100: #dcfae6,
    200: #abefc6,
    300: #75e0a7,
    400: #47cd89,
    500: #17b26a,
    600: #079455,
    700: #067647,
    800: #085d3a,
    900: #074d31,
    950: #053321,
  )
);

$v2-light-theme-gray-blue: get-untitled-ui-colors(
  'gray-blue',
  (
    25: #fcfcfd,
    50: #f8f9fc,
    100: #eaecf5,
    200: #d5d9eb,
    300: #b3b8db,
    400: #717bbc,
    500: #4e5ba6,
    600: #3e4784,
    700: #363f72,
    800: #293056,
    900: #101323,
    950: #0d0f1c,
  )
);

$v2-light-theme-gray-cool: get-untitled-ui-colors(
  'gray-cool',
  (
    25: #fcfcfd,
    50: #f9f9fb,
    100: #eff1f5,
    200: #dcdfea,
    300: #b9c0d4,
    400: #7d89b0,
    500: #5d6b98,
    600: #4a5578,
    700: #404968,
    800: #30374f,
    900: #111322,
    950: #0e101b,
  )
);

$v2-light-theme-gray-modern: get-untitled-ui-colors(
  'gray-modern',
  (
    25: #fcfcfd,
    50: #f8fafc,
    100: #eef2f6,
    200: #e3e8ef,
    300: #cdd5df,
    400: #9aa4b2,
    500: #697586,
    600: #4b5565,
    700: #364152,
    800: #202939,
    900: #121926,
    950: #0d121c,
  )
);

$v2-light-theme-gray-neutral: get-untitled-ui-colors(
  'gray-neutral',
  (
    25: #fcfcfd,
    50: #f9fafb,
    100: #f3f4f6,
    200: #e5e7eb,
    300: #d2d6db,
    400: #9da4ae,
    500: #6c737f,
    600: #4d5761,
    700: #384250,
    800: #1f2a37,
    900: #111927,
    950: #0d121c,
  )
);

$v2-light-theme-gray-iron: get-untitled-ui-colors(
  'gray-iron',
  (
    25: #fcfcfc,
    50: #fafafa,
    100: #f4f4f5,
    200: #e4e4e7,
    300: #d1d1d6,
    400: #a0a0ab,
    500: #70707b,
    600: #51525c,
    700: #3f3f46,
    800: #26272b,
    900: #18181b,
    950: #131316,
  )
);

$v2-light-theme-gray-true: get-untitled-ui-colors(
  'gray-true',
  (
    25: #fcfcfc,
    50: #fafafa,
    100: #f5f5f5,
    200: #e5e5e5,
    300: #d6d6d6,
    400: #a3a3a3,
    500: #737373,
    600: #525252,
    700: #424242,
    800: #292929,
    900: #141414,
    950: #0f0f0f,
  )
);

$v2-light-theme-gray-warm: get-untitled-ui-colors(
  'gray-warm',
  (
    25: #fdfdfc,
    50: #fafaf9,
    100: #f5f5f4,
    200: #e7e5e4,
    300: #d7d3d0,
    400: #a9a29d,
    500: #79716b,
    600: #57534e,
    700: #44403c,
    800: #292524,
    900: #1c1917,
    950: #171412,
  )
);

$v2-light-theme-moss: get-untitled-ui-colors(
  'moss',
  (
    25: #fafdf7,
    50: #f5fbee,
    100: #e6f4d7,
    200: #ceeab0,
    300: #acdc79,
    400: #86cb3c,
    500: #669f2a,
    600: #4f7a21,
    700: #3f621a,
    800: #335015,
    900: #2b4212,
    950: #1a280b,
  )
);

$v2-light-theme-green-light: get-untitled-ui-colors(
  'green-light',
  (
    25: #fafef5,
    50: #f3fee7,
    100: #e4fbcc,
    200: #d0f8ab,
    300: #a6ef67,
    400: #85e13a,
    500: #66c61c,
    600: #4ca30d,
    700: #3b7c0f,
    800: #326212,
    900: #2b5314,
    950: #15290a,
  )
);

$v2-light-theme-green: get-untitled-ui-colors(
  'green',
  (
    25: #f6fef9,
    50: #edfcf2,
    100: #d3f8df,
    200: #aaf0c4,
    300: #73e2a3,
    400: #3ccb7f,
    500: #16b364,
    600: #099250,
    700: #087443,
    800: #095c37,
    900: #084c2e,
    950: #052e1c,
  )
);

$v2-light-theme-teal: get-untitled-ui-colors(
  'teal',
  (
    25: #f6fefc,
    50: #f0fdf9,
    100: #ccfbef,
    200: #99f6e0,
    300: #5fe9d0,
    400: #2ed3b7,
    500: #15b79e,
    600: #0e9384,
    700: #107569,
    800: #125d56,
    900: #134e48,
    950: #0a2926,
  )
);

$v2-light-theme-cyan: get-untitled-ui-colors(
  'cyan',
  (
    25: #f5feff,
    50: #ecfdff,
    100: #cff9fe,
    200: #a5f0fc,
    300: #67e3f9,
    400: #2ce,
    500: #06aed4,
    600: #088ab2,
    700: #0e7090,
    800: #155b75,
    900: #164c63,
    950: #0d2d3a,
  )
);

$v2-light-theme-blue-light: get-untitled-ui-colors(
  'blue-light',
  (
    25: #f5fbff,
    50: #f0f9ff,
    100: #e0f2fe,
    200: #b9e6fe,
    300: #7cd4fd,
    400: #36bffa,
    500: #0ba5ec,
    600: #0086c9,
    700: #026aa2,
    800: #065986,
    900: #0b4a6f,
    950: #062c41,
  )
);

$v2-light-theme-blue: get-untitled-ui-colors(
  'blue',
  (
    25: #f5faff,
    50: #eff8ff,
    100: #d1e9ff,
    200: #b2ddff,
    300: #84caff,
    400: #53b1fd,
    500: #2e90fa,
    600: #1570ef,
    700: #175cd3,
    800: #1849a9,
    900: #194185,
    950: #102a56,
  )
);

$v2-light-theme-blue-dark: get-untitled-ui-colors(
  'blue-dark',
  (
    25: #f5f8ff,
    50: #eff4ff,
    100: #d1e0ff,
    200: #b2ccff,
    300: #84adff,
    400: #528bff,
    500: #2970ff,
    600: #155eef,
    700: #004eeb,
    800: #0040c1,
    900: #00359e,
    950: #026,
  )
);

$v2-light-theme-indigo: get-untitled-ui-colors(
  'indigo',
  (
    25: #f5f8ff,
    50: #eef4ff,
    100: #e0eaff,
    200: #c7d7fe,
    300: #a4bcfd,
    400: #8098f9,
    500: #6172f3,
    600: #444ce7,
    700: #3538cd,
    800: #2d31a6,
    900: #2d3282,
    950: #1f235b,
  )
);

$v2-light-theme-violet: get-untitled-ui-colors(
  'violet',
  (
    25: #fbfaff,
    50: #f5f3ff,
    100: #ece9fe,
    200: #ddd6fe,
    300: #c3b5fd,
    400: #a48afb,
    500: #875bf7,
    600: #7839ee,
    700: #6927da,
    800: #5720b7,
    900: #491c96,
    950: #2e125e,
  )
);

$v2-light-theme-purple: get-untitled-ui-colors(
  'purple',
  (
    25: #fafaff,
    50: #f4f3ff,
    100: #ebe9fe,
    200: #d9d6fe,
    300: #bdb4fe,
    400: #9b8afb,
    500: #7a5af8,
    600: #6938ef,
    700: #5925dc,
    800: #4a1fb8,
    900: #3e1c96,
    950: #27115f,
  )
);

$v2-light-theme-fuchsia: get-untitled-ui-colors(
  'fucshia',
  (
    25: #fefaff,
    50: #fdf4ff,
    100: #fbe8ff,
    200: #f6d0fe,
    300: #eeaafd,
    400: #e478fa,
    500: #d444f1,
    600: #ba24d5,
    700: #9f1ab1,
    800: #821890,
    900: #6f1877,
    950: #47104c,
  )
);

$v2-light-theme-pink: get-untitled-ui-colors(
  'pink',
  (
    25: #fef6fb,
    50: #fdf2fa,
    100: #fce7f6,
    200: #fcceee,
    300: #faa7e0,
    400: #f670c7,
    500: #ee46bc,
    600: #dd2590,
    700: #c11574,
    800: #9e165f,
    900: #851651,
    950: #4e0d30,
  )
);

$v2-light-theme-rose: get-untitled-ui-colors(
  'rose',
  (
    25: #fff5f6,
    50: #fff1f3,
    100: #ffe4e8,
    200: #fecdd6,
    300: #fea3b4,
    400: #fd6f8e,
    500: #f63d68,
    600: #e31b54,
    700: #c01048,
    800: #a11043,
    900: #89123e,
    950: #510b24,
  )
);

$v2-light-theme-orange-dark: get-untitled-ui-colors(
  'orange-dark',
  (
    25: #fff9f5,
    50: #fff4ed,
    100: #ffe6d5,
    200: #ffd6ae,
    300: #ff9c66,
    400: #ff692e,
    500: #ff4405,
    600: #e62e05,
    700: #bc1b06,
    800: #97180c,
    900: #771a0d,
    950: #57130a,
  )
);

$v2-light-theme-orange: get-untitled-ui-colors(
  'orange',
  (
    25: #fefaf5,
    50: #fef6ee,
    100: #fdead7,
    200: #f9dbaf,
    300: #f7b27a,
    400: #f38744,
    500: #ef6820,
    600: #e04f16,
    700: #b93815,
    800: #932f19,
    900: #772917,
    950: #511c10,
  )
);

$v2-light-theme-yellow: get-untitled-ui-colors(
  'yellow',
  (
    25: #fefdf0,
    50: #fefbe8,
    100: #fef7c3,
    200: #feee95,
    300: #fde272,
    400: #fac515,
    500: #eaaa08,
    600: #ca8504,
    700: #a15c07,
    800: #854a0e,
    900: #713b12,
    950: #542c0d,
  )
);

// 一つの Map に統合
$v2-light-theme-colors: map-merge-multiple(
  $v2-light-theme-main-colors,
  $v2-light-theme-reds,
  $v2-light-theme-yellows,
  $v2-light-theme-greens,
  $v2-light-theme-pinks,
  $v2-light-theme-cyans,
  $v2-light-theme-oranges,
  $v2-light-theme-blues,
  $v2-light-theme-purples,
  $v2-light-theme-browns,
  $v2-light-theme-base-colors,
  $v2-light-theme-gray,
  $v2-light-theme-primary,
  $v2-light-theme-error,
  $v2-light-theme-warning,
  $v2-light-theme-success,
  $v2-light-theme-gray-blue,
  $v2-light-theme-gray-cool,
  $v2-light-theme-gray-modern,
  $v2-light-theme-gray-neutral,
  $v2-light-theme-gray-iron,
  $v2-light-theme-gray-true,
  $v2-light-theme-gray-warm,
  $v2-light-theme-moss,
  $v2-light-theme-green-light,
  $v2-light-theme-green,
  $v2-light-theme-teal,
  $v2-light-theme-cyan,
  $v2-light-theme-blue-light,
  $v2-light-theme-blue,
  $v2-light-theme-blue-dark,
  $v2-light-theme-indigo,
  $v2-light-theme-violet,
  $v2-light-theme-purple,
  $v2-light-theme-fuchsia,
  $v2-light-theme-pink,
  $v2-light-theme-rose,
  $v2-light-theme-orange-dark,
  $v2-light-theme-orange,
  $v2-light-theme-yellow
);

// ============================================================
// Colors - Dark theme
// ============================================================
// Main colors
// ベースとなる色の定義
$v2-dark-theme-primary: #4e46b4;
$v2-dark-theme-secondary: #40a69f;
$v2-dark-theme-border: #444;
// 条件によって重ねる色や透明度の定義
$v2-dark-theme-hover-mix-color: #fff; // ホバー時に重ねがけする色
// ホバー時の色の重ねがけ用の weight 値
// mix() に渡すため、下地の色を何%使うかで指定
$v2-dark-theme-hover-mix-weight: 88%; // 重ねる色の方を12%で重ねがけ
$v2-dark-theme-supportive-color-60-opacity: 56%; // rgba() に渡す
$v2-dark-theme-supportive-color-10-opacity: 8%; // rgba() に渡す

$v2-dark-theme-main-colors: (
  primary100: $v2-dark-theme-primary,
  primary100-hover: mix($v2-dark-theme-primary, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight),
  secondary100: $v2-dark-theme-secondary,
  secondary100-hover:  mix($v2-dark-theme-secondary, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight),
  background: #0b0b0b,
  surface: #1f1f1f,
  backdrop: rgba(#000, 56%), // モーダルとか出したときのオーバーレイのやつ
  border: $v2-dark-theme-border,
  border-hover: mix($v2-dark-theme-border, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight),
  primary-button-text: #fff, // テキストやアイコン用
  secondary-button-text: #000,
  primary-body-text: #fff,
  secondary-body-text: #999ca0,
  primary-hover: rgba(#fff, 12%),
  secondary-hover: rgba($v2-dark-theme-primary, 32%), // テキストだけのボタンにホバーしたときにつける
  focus: rgba($v2-dark-theme-primary, 20%), // button や input の focus リングの色
  symbol: #ebebeb,
);

// Supportive colors
$v2-dark-theme-reds: get-supportive-colors('red', #d33030, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);
$v2-dark-theme-yellows: get-supportive-colors('yellow', #ffb319, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);
$v2-dark-theme-greens: get-supportive-colors('green', #2e7d32, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);
$v2-dark-theme-pinks: get-supportive-colors('pink', #ff4e64, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);
$v2-dark-theme-cyans: get-supportive-colors('cyan', #95f1d5, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);
$v2-dark-theme-oranges: get-supportive-colors('orange', #b3804a, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);
$v2-dark-theme-blues: get-supportive-colors('blue', #3448f0, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);
$v2-dark-theme-purples: get-supportive-colors('purple', #5c33cf, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);
$v2-dark-theme-browns: get-supportive-colors('brown', #725550, $v2-dark-theme-hover-mix-color, $v2-dark-theme-hover-mix-weight, $v2-dark-theme-supportive-color-60-opacity, $v2-dark-theme-supportive-color-10-opacity);

// Untitled UI Colors
// Untitled UI ベースのカラーパレット
$v2-dark-theme-base-colors: get-untitled-ui-colors(
  'base',
  (
    white: #fff,
    black: #000,
  )
);

$v2-dark-theme-gray: get-untitled-ui-colors(
  'gray',
  (
    25: #fcfcfd,
    50: #f9fafb,
    100: #f2f4f7,
    200: #eaecf0,
    300: #d0d5dd,
    400: #98a2b3,
    500: #667085,
    600: #475467,
    700: #344054,
    800: #1d2939,
    900: #101828,
    950: #0c111d,
  )
);

$v2-dark-theme-primary: get-untitled-ui-colors(
  'primary',
  (
    25: #fcfaff,
    50: #f9f5ff,
    100: #f4ebff,
    200: #e9d7fe,
    300: #d6bbfb,
    400: #b692f6,
    500: #9e77ed,
    600: #7f56d9,
    700: #6941c6,
    800: #53389e,
    900: #42307d,
    950: #2c1c5f,
  )
);

$v2-dark-theme-error: get-untitled-ui-colors(
  'error',
  (
    25: #fffbfa,
    50: #fef3f2,
    100: #fee4e2,
    200: #fecdca,
    300: #fda29b,
    400: #f97066,
    500: #f04438,
    600: #d92d20,
    700: #b42318,
    800: #912018,
    900: #7a271a,
    950: #55160c,
  )
);

$v2-dark-theme-warning: get-untitled-ui-colors(
  'warning',
  (
    25: #fffcf5,
    50: #fffaeb,
    100: #fef0c7,
    200: #fedf89,
    300: #fec84b,
    400: #fdb022,
    500: #f79009,
    600: #dc6803,
    700: #b54708,
    800: #93370d,
    900: #7a2e0e,
    950: #4e1d09,
  )
);

$v2-dark-theme-success: get-untitled-ui-colors(
  'success',
  (
    25: #f6fef9,
    50: #ecfdf3,
    100: #dcfae6,
    200: #abefc6,
    300: #75e0a7,
    400: #47cd89,
    500: #17b26a,
    600: #079455,
    700: #067647,
    800: #085d3a,
    900: #074d31,
    950: #053321,
  )
);

$v2-dark-theme-gray-blue: get-untitled-ui-colors(
  'gray-blue',
  (
    25: #fcfcfd,
    50: #f8f9fc,
    100: #eaecf5,
    200: #d5d9eb,
    300: #b3b8db,
    400: #717bbc,
    500: #4e5ba6,
    600: #3e4784,
    700: #363f72,
    800: #293056,
    900: #101323,
    950: #0d0f1c,
  )
);

$v2-dark-theme-gray-cool: get-untitled-ui-colors(
  'gray-cool',
  (
    25: #fcfcfd,
    50: #f9f9fb,
    100: #eff1f5,
    200: #dcdfea,
    300: #b9c0d4,
    400: #7d89b0,
    500: #5d6b98,
    600: #4a5578,
    700: #404968,
    800: #30374f,
    900: #111322,
    950: #0e101b,
  )
);

$v2-dark-theme-gray-modern: get-untitled-ui-colors(
  'gray-modern',
  (
    25: #fcfcfd,
    50: #f8fafc,
    100: #eef2f6,
    200: #e3e8ef,
    300: #cdd5df,
    400: #9aa4b2,
    500: #697586,
    600: #4b5565,
    700: #364152,
    800: #202939,
    900: #121926,
    950: #0d121c,
  )
);

$v2-dark-theme-gray-neutral: get-untitled-ui-colors(
  'gray-neutral',
  (
    25: #fcfcfd,
    50: #f9fafb,
    100: #f3f4f6,
    200: #e5e7eb,
    300: #d2d6db,
    400: #9da4ae,
    500: #6c737f,
    600: #4d5761,
    700: #384250,
    800: #1f2a37,
    900: #111927,
    950: #0d121c,
  )
);

$v2-dark-theme-gray-iron: get-untitled-ui-colors(
  'gray-iron',
  (
    25: #fcfcfc,
    50: #fafafa,
    100: #f4f4f5,
    200: #e4e4e7,
    300: #d1d1d6,
    400: #a0a0ab,
    500: #70707b,
    600: #51525c,
    700: #3f3f46,
    800: #26272b,
    900: #18181b,
    950: #131316,
  )
);

$v2-dark-theme-gray-true: get-untitled-ui-colors(
  'gray-true',
  (
    25: #fcfcfc,
    50: #fafafa,
    100: #f5f5f5,
    200: #e5e5e5,
    300: #d6d6d6,
    400: #a3a3a3,
    500: #737373,
    600: #525252,
    700: #424242,
    800: #292929,
    900: #141414,
    950: #0f0f0f,
  )
);

$v2-dark-theme-gray-warm: get-untitled-ui-colors(
  'gray-warm',
  (
    25: #fdfdfc,
    50: #fafaf9,
    100: #f5f5f4,
    200: #e7e5e4,
    300: #d7d3d0,
    400: #a9a29d,
    500: #79716b,
    600: #57534e,
    700: #44403c,
    800: #292524,
    900: #1c1917,
    950: #171412,
  )
);

$v2-dark-theme-moss: get-untitled-ui-colors(
  'moss',
  (
    25: #fafdf7,
    50: #f5fbee,
    100: #e6f4d7,
    200: #ceeab0,
    300: #acdc79,
    400: #86cb3c,
    500: #669f2a,
    600: #4f7a21,
    700: #3f621a,
    800: #335015,
    900: #2b4212,
    950: #1a280b,
  )
);

$v2-dark-theme-green-dark: get-untitled-ui-colors(
  'green-dark',
  (
    25: #fafef5,
    50: #f3fee7,
    100: #e4fbcc,
    200: #d0f8ab,
    300: #a6ef67,
    400: #85e13a,
    500: #66c61c,
    600: #4ca30d,
    700: #3b7c0f,
    800: #326212,
    900: #2b5314,
    950: #15290a,
  )
);

$v2-dark-theme-green: get-untitled-ui-colors(
  'green',
  (
    25: #f6fef9,
    50: #edfcf2,
    100: #d3f8df,
    200: #aaf0c4,
    300: #73e2a3,
    400: #3ccb7f,
    500: #16b364,
    600: #099250,
    700: #087443,
    800: #095c37,
    900: #084c2e,
    950: #052e1c,
  )
);

$v2-dark-theme-teal: get-untitled-ui-colors(
  'teal',
  (
    25: #f6fefc,
    50: #f0fdf9,
    100: #ccfbef,
    200: #99f6e0,
    300: #5fe9d0,
    400: #2ed3b7,
    500: #15b79e,
    600: #0e9384,
    700: #107569,
    800: #125d56,
    900: #134e48,
    950: #0a2926,
  )
);

$v2-dark-theme-cyan: get-untitled-ui-colors(
  'cyan',
  (
    25: #f5feff,
    50: #ecfdff,
    100: #cff9fe,
    200: #a5f0fc,
    300: #67e3f9,
    400: #2ce,
    500: #06aed4,
    600: #088ab2,
    700: #0e7090,
    800: #155b75,
    900: #164c63,
    950: #0d2d3a,
  )
);

$v2-dark-theme-blue-dark: get-untitled-ui-colors(
  'blue-dark',
  (
    25: #f5fbff,
    50: #f0f9ff,
    100: #e0f2fe,
    200: #b9e6fe,
    300: #7cd4fd,
    400: #36bffa,
    500: #0ba5ec,
    600: #0086c9,
    700: #026aa2,
    800: #065986,
    900: #0b4a6f,
    950: #062c41,
  )
);

$v2-dark-theme-blue: get-untitled-ui-colors(
  'blue',
  (
    25: #f5faff,
    50: #eff8ff,
    100: #d1e9ff,
    200: #b2ddff,
    300: #84caff,
    400: #53b1fd,
    500: #2e90fa,
    600: #1570ef,
    700: #175cd3,
    800: #1849a9,
    900: #194185,
    950: #102a56,
  )
);

$v2-dark-theme-blue-dark: get-untitled-ui-colors(
  'blue-dark',
  (
    25: #f5f8ff,
    50: #eff4ff,
    100: #d1e0ff,
    200: #b2ccff,
    300: #84adff,
    400: #528bff,
    500: #2970ff,
    600: #155eef,
    700: #004eeb,
    800: #0040c1,
    900: #00359e,
    950: #026,
  )
);

$v2-dark-theme-indigo: get-untitled-ui-colors(
  'indigo',
  (
    25: #f5f8ff,
    50: #eef4ff,
    100: #e0eaff,
    200: #c7d7fe,
    300: #a4bcfd,
    400: #8098f9,
    500: #6172f3,
    600: #444ce7,
    700: #3538cd,
    800: #2d31a6,
    900: #2d3282,
    950: #1f235b,
  )
);

$v2-dark-theme-violet: get-untitled-ui-colors(
  'violet',
  (
    25: #fbfaff,
    50: #f5f3ff,
    100: #ece9fe,
    200: #ddd6fe,
    300: #c3b5fd,
    400: #a48afb,
    500: #875bf7,
    600: #7839ee,
    700: #6927da,
    800: #5720b7,
    900: #491c96,
    950: #2e125e,
  )
);

$v2-dark-theme-purple: get-untitled-ui-colors(
  'purple',
  (
    25: #fafaff,
    50: #f4f3ff,
    100: #ebe9fe,
    200: #d9d6fe,
    300: #bdb4fe,
    400: #9b8afb,
    500: #7a5af8,
    600: #6938ef,
    700: #5925dc,
    800: #4a1fb8,
    900: #3e1c96,
    950: #27115f,
  )
);

$v2-dark-theme-fuchsia: get-untitled-ui-colors(
  'fucshia',
  (
    25: #fefaff,
    50: #fdf4ff,
    100: #fbe8ff,
    200: #f6d0fe,
    300: #eeaafd,
    400: #e478fa,
    500: #d444f1,
    600: #ba24d5,
    700: #9f1ab1,
    800: #821890,
    900: #6f1877,
    950: #47104c,
  )
);

$v2-dark-theme-pink: get-untitled-ui-colors(
  'pink',
  (
    25: #fef6fb,
    50: #fdf2fa,
    100: #fce7f6,
    200: #fcceee,
    300: #faa7e0,
    400: #f670c7,
    500: #ee46bc,
    600: #dd2590,
    700: #c11574,
    800: #9e165f,
    900: #851651,
    950: #4e0d30,
  )
);

$v2-dark-theme-rose: get-untitled-ui-colors(
  'rose',
  (
    25: #fff5f6,
    50: #fff1f3,
    100: #ffe4e8,
    200: #fecdd6,
    300: #fea3b4,
    400: #fd6f8e,
    500: #f63d68,
    600: #e31b54,
    700: #c01048,
    800: #a11043,
    900: #89123e,
    950: #510b24,
  )
);

$v2-dark-theme-orange-dark: get-untitled-ui-colors(
  'orange-dark',
  (
    25: #fff9f5,
    50: #fff4ed,
    100: #ffe6d5,
    200: #ffd6ae,
    300: #ff9c66,
    400: #ff692e,
    500: #ff4405,
    600: #e62e05,
    700: #bc1b06,
    800: #97180c,
    900: #771a0d,
    950: #57130a,
  )
);

$v2-dark-theme-orange: get-untitled-ui-colors(
  'orange',
  (
    25: #fefaf5,
    50: #fef6ee,
    100: #fdead7,
    200: #f9dbaf,
    300: #f7b27a,
    400: #f38744,
    500: #ef6820,
    600: #e04f16,
    700: #b93815,
    800: #932f19,
    900: #772917,
    950: #511c10,
  )
);

$v2-dark-theme-yellow: get-untitled-ui-colors(
  'yellow',
  (
    25: #fefdf0,
    50: #fefbe8,
    100: #fef7c3,
    200: #feee95,
    300: #fde272,
    400: #fac515,
    500: #eaaa08,
    600: #ca8504,
    700: #a15c07,
    800: #854a0e,
    900: #713b12,
    950: #542c0d,
  )
);

// 一つの Map に統合
$v2-dark-theme-colors: map-merge-multiple(
  $v2-dark-theme-main-colors,
  $v2-dark-theme-reds,
  $v2-dark-theme-yellows,
  $v2-dark-theme-greens,
  $v2-dark-theme-pinks,
  $v2-dark-theme-cyans,
  $v2-dark-theme-oranges,
  $v2-dark-theme-blues,
  $v2-dark-theme-purples,
  $v2-dark-theme-browns,
  $v2-dark-theme-base-colors,
  $v2-dark-theme-gray,
  $v2-dark-theme-primary,
  $v2-dark-theme-error,
  $v2-dark-theme-warning,
  $v2-dark-theme-success,
  $v2-dark-theme-gray-blue,
  $v2-dark-theme-gray-cool,
  $v2-dark-theme-gray-modern,
  $v2-dark-theme-gray-neutral,
  $v2-dark-theme-gray-iron,
  $v2-dark-theme-gray-true,
  $v2-dark-theme-gray-warm,
  $v2-dark-theme-moss,
  $v2-dark-theme-green-dark,
  $v2-dark-theme-green,
  $v2-dark-theme-teal,
  $v2-dark-theme-cyan,
  $v2-dark-theme-blue-dark,
  $v2-dark-theme-blue,
  $v2-dark-theme-blue-dark,
  $v2-dark-theme-indigo,
  $v2-dark-theme-violet,
  $v2-dark-theme-purple,
  $v2-dark-theme-fuchsia,
  $v2-dark-theme-pink,
  $v2-dark-theme-rose,
  $v2-dark-theme-orange-dark,
  $v2-dark-theme-orange,
  $v2-dark-theme-yellow
);

$v2-dark-theme-colors: map-merge-multiple($v2-dark-theme-main-colors, $v2-dark-theme-reds, $v2-dark-theme-yellows, $v2-dark-theme-greens, $v2-dark-theme-pinks, $v2-dark-theme-cyans, $v2-dark-theme-oranges, $v2-dark-theme-blues, $v2-dark-theme-purples, $v2-dark-theme-browns);

// ============================================================
// Fonts
// ============================================================
// Font 周りは Bootstrap の reboot などの基盤を使いたいため共通化・継承
// reboot などのベースは styles/common/* の Bootstrap の reboot を利用しつつも
// V2 での weight の指定はこちらの `$v2-` 接頭辞付きの変数を利用する
$v2-font-weight-regular: $font-weight-normal;
$v2-font-weight-medium: $font-weight-medium;
$v2-font-weight-semiBold: $font-weight-semiBold;
$v2-font-weight-bold: $font-weight-bold;

// ============================================================
// Header
// ============================================================
$v2-desktop-header-height: 80px;
$v2-mobile-header-height: 72px;
$v2-attack-list-header-height: 101px;
$v2-pms-list-page-subheader-height: 88px;

$bashboard-nav-height: 40px;
$container-wide-padding: 32px;
$fixed-bulk-group-height: 56px;

// ============================================================
// z-index
// ============================================================
$v2-outside-overlay-z-index-layer1: 2000;
$v2-outside-overlay-z-index-layer2: 3000;
