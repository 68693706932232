@import "@styles-v2/common";

.footer {
  padding: 64px 0 48px 0;
  background: #53389eff;

  @include media-breakpoint-down(sm) {
    padding: 48px 0 32px;
  }
}

.item {
  margin-bottom: 8px;
  color: #e9d7feff;
  font-size: 16px;
  line-height: 24px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.bottom {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid c(gray-200);

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    padding-top: 32px;
    align-items: start;
    height: 100%;
    gap: 18px;
  }
}

.bottom__copyright {
  p {
    color: #e9d7feff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.bottom__snsLink {
  //
}
